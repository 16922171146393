// import React from "react"
// import SEO from "../components/SEO/seo"
// import Layout3 from "./../components/Layout/Layout-3/layout-3"
// import DrainThankYou from "../components/Drain/thankyou"

// const DrainCleaningThankYou = () => (
//   <Layout3>
//     <SEO title="Drain Cleaning Thank you" />
//     <DrainThankYou />
//   </Layout3>
// )

// export default DrainCleaningThankYou

import React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout/Layout-3/layout-3"
import SEO from "../components/seo"

const DrainCleaningThankYou = () => (
  <Layout>
    <SEO title="drain cleaning thank you " />
    <h1>Hi from the drain cleaning thank you page</h1>
    <p>Hi from the drain cleaning thank you page</p>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default DrainCleaningThankYou
